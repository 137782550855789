
  .mobileApp{
	margin-top: 0.5%;
  margin-left: 0.5%;
  height: 100vh;
  width:220vh;
  background-size:cover;
  background-image: url(../../../public/assets/images/Slice.png);
  }
  .mobileAppimg{
    margin-top: 0.5%;
    margin-left: 0.5%;
  }
  .ENTERPRISE {
    margin-top: 0.5%;
    margin-left: 0.5%;
    height: 100vh;
    width:220vh;
    background-size:cover;
    background-image: url(../../../public/assets/images/mobilecharg.png);
    }

    .imgs{
      margin-top: 0.5%;
    margin-left: 0.5%;
    }
  .mobileAppimg img {
    width: 1340px;
    display: none;
 }
 @media only screen and (max-width: 750px){
  .mobileAppimg img {
display: flex;
    width: 700px;
   margin-left: 1.5%;
   margin-top: -1.5%;
 }
 .mobileApp{
  display: none;
 }
}

  @media only screen and (max-width: 500px){
    .mobileAppimg img {margin-top: 2%;
      display: flex;
      width: 700px;
     margin-left: 2%;
   }
   .mobileApp{
    display: none;
   }
  }

  .imgs img {
    
    width: 1340px;
    display: none;
 }
 @media only screen and (max-width: 750px){
  .imgs img {
    display: flex;
    width: 700px;
   margin-left: 1.5%;
   margin-top: -1.5%;
 }
 .ENTERPRISE{
  display: none;
 }
}

  @media only screen and (max-width: 500px){
    
    .imgs img {margin-top: 2%;
      display: flex;
      width: 700px;
     margin-left: 2%;
   }
   .ENTERPRISE{
    display: none;
   }
  }
 