
.welcome{
	position:relative;
	font-family: Inter;
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	color: #000000;
}
.Accelerate
{
	position:relative;
	font-family: "Nunito",sans-serif;;
	font-style: normal;
	font-weight: bold;
	font-size: 35px;
	color: #249a93;
}
.textbox_class{
	background: #FFFFFF;
	padding-left: 10px;
	font: Rubik;
	font-style: italic;
	border:none;
	border-radius: 36px;
	color: #000000;
	margin: 5px 0px 5px 5px; 
	
}

.cta{
	background: #06669e;
	border-radius: 36px;
	border-style: none;
	width: auto;
	height: 57%;
	color: #F2F2F2;
	margin-top: 2%;
}
.Link{
	text-decoration: none;
	color: #F2F2F2;
}
.Link:hover{
	color: #a8ff05;
}

.fill_details{
	position: relative;
	font-family: Rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	color: #FAB804;
	margin: 5px 5px 5px 5px; 
}

.mini_form{
	left: 0px;
	top: 0px;
	background: #001D47;
	border-radius: 20px;
}

.mini_form_heading{
	position: relative;
	font-family: Rubik;
	font-style: normal;
	font-weight: bold;
	font-size: 30px;
	color: #FFFFFF;
	margin: 5px 5px 5px 5px; 
}
.services_products_main{
	
	padding-left: 20px;
	
}
.services_content{
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 36px;
color: #000000;
}

.servicebox{
	position: relative;
	width: 300px;
	height: 201px;
	background: #C4C4C4;
	border-radius: 0px 20px 20px 0px;
}
.services_products{
	position: relative;
	width: 300px;
	height: 650px;
	font-family: Rubik;
	font-style: normal;
	background: #FAB804;
	border-radius: 20px;
	margin-left: 5px;
	padding-left: 5px;
}
.service_content{
	font-size: 24px;
	padding-left: 10px;
}
.service_name{
	font-weight:bold;
}
.read_more{
	font-size: 18px;
	font-weight:bold;
	padding-left: 5px;
}
.services_products_text{
	font-size: 20px;
	font-weight: bold;
	margin-left: 10px;
}
.request_call_back{
	position: relative;
	width: 250px;
	height: 50px;
	left: 0px;
	top: 0px;
	background: #FAB804;
	border-radius: 36px;
}
.client_products{
	position: relative;
	width: 300px;
	height: 650px;
	font-family: Rubik;
	font-style: normal;
	background: #F2F2F2;
	border-radius: 20px;
	margin-left: 5px;
	padding-left: 5px;
}
.client_content{
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	color: #000000;
}

.client_name{
	font-weight:bold;
	font-size: 24px;
}
.banner{
	position: relative;
	width: 870px;
	height: 525px;
	left: 0px;
	top: 0px;
	background: #C4C4C4;
	border-radius: 20px 0px 0px 20px;
}



.landing_button1{
	position: absolute;
	width: 415px;
	height: 72px;
	left: 100px;
	top: 563px;
	background: #FAB804;
	border-radius: 36px;
}
.evimg{
	margin-left: 2%;
	background-image: url(../../../public/assets/images/car.jpg);
	background-size:cover;
	animation: myanimation 10s infinite;
   z-index: 1;
}

.evcolor{
	
	margin-left: 1%;
	
	background-color:rgb(247, 247, 247);
	background-size:cover;
	height: 600%;
	max-width: 98.5%;
}
.fontColor{
	top: 0%;
	color:#a8ff05;
	font-family: "Nunito",sans-serif;
	
}
.fontColors{
	top: -15%;
	color:#a8ff05;
	font-family: "Nunito",sans-serif;
}

.fontColorsss{
	
	font-style: normal;
	font-weight: bold;
	margin-left: 10%;
margin-bottom: -2%;
	color:#a8ff05;
	font-family: "Nunito",sans-serif;
	font-size:30px;
}
.fontColorss{
	font-style: normal;
	font-weight: bold;
	margin-right: 8%;
	font-size:30px;
	top: -20%;
	color:#a8ff05;
	font-family: "Nunito",sans-serif;
	
	
}
.contactimg{
	margin-left: 0.8%;
	margin-top: 0.5%;
	padding-top:12%;
	background-image: url(../../../public/assets/images/city.jpg);
	background-size:cover;
	height: 100vh;
	
	width: 100%;
	font-family: "Nunito",sans-serif;
	
	
}
.contact{
	position: relative;
	top:-50%;
	right: -40%;
	color:  #fbfcf8;
	font-family: "Nunito",sans-serif;
}
.spec{
	
	margin-bottom: 1%;
	width: auto;
	color:  #fdfcfb;
	font-family: "Nunito",sans-serif;
	font-size:22px;
	margin-left: 28%;
}
.speci{
	
	width: auto;
	color:  #fdfcfb;
	font-family: "Nunito",sans-serif;
	font-size:22px;
	margin-left: 28%;
}
.speciheader{
	font-weight: bold;
	
	color:  #fcfaf8;
	font-family: "Nunito",sans-serif;
	font-size:25px;
	margin-left: 25%;
	border-bottom: 1px solid rgb(253, 249, 249);
	width: auto;
}
.specheader{
	font-weight: bold;
	margin-bottom: 5%;
	color:  #fdfcfa;
	font-family: "Nunito",sans-serif;
	font-size:25px;
	margin-left: 30%;
	
	border-spacing: 10%;
	border-bottom: 1px solid rgb(253, 249, 249);
	width: auto;
}
.contactButton{
	background: #06669e;
	border-radius: 36px;
	border-style: none;
	width: 23%;
	height: 15%;
	padding-top: 0.7%;
	color: #F2F2F2;
	position: relative;
	top: -25%;
	right:-40%;
	font-family: "Nunito",sans-serif;
	font-size:15px;
	
}
.contactButton2{
	background: #06669e;
	border-radius: 36px;
	border-style: none;
	width: 20%;
	height: 15%;
	padding-top: 0.7%;
	color: #F2F2F2;
	position: relative;
	top: -120%;
	right:-40%;
	font-family: "Nunito",sans-serif;
	font-size:15px;
	
}
.pera{
	margin-top: 2%;
	font-size: 15.4px;
	font-family: "Source Sans Pro",sans-serif;
}
.padding-left{
	padding-left: 5%;
}
.evcharging{
	
	background-size:cover;
	max-height: 100%;
	
}

/*@keyframes myanimation {
	
	
	0%{background-image: url(../../../public/assets/images/evc.jpg)}
	50% {background-image: url(../../../public/assets/images/car.jpg);}
  }*/
  @media only screen and (max-width: 500px) {
	
	.contactimg{
		margin-left: 3%;
		margin-top: 3%;
	
		
		
		width: 177%;
		font-family: "Nunito",sans-serif;
		
		
	}
	.evimg{
		
		background-image: url(../../../public/assets/images/car-6943487.jpg);
	}
	.homeSize{
		margin-left: 2.5%;
		width: 177%;
			}
			.fontColorsss{
				margin-left:-8%;
				margin-bottom: 1%;
			}
			.Accelerate{
				font-size: 33px;
			}
			.Specifi{
				font-size: 12px;
			}
			.pera{
				font-size: 12px;
			}
			
			
  }

 
