.footbar {
	margin-top: 5%;
position: relative;
width: auto;
height: 100%;
left: 0px;
background: #242430;
}

.max-height-25vh {
	max-height: 25vh;
	
 }
.nav-logo {
	margin:0 0 0 40px;
	color: white;
	padding-top: 0px;
	justify-content:center;
}

.nav-links {
	display: grid;
	grid-template-columns: repeat(5, auto);
	grid-gap: 5px;
	padding-top: 5px;
	list-style: none;
}

.sign_up_container{	
	padding: 0px 0px 0px 0px;
	margin:0px 0px 0px 0px;
	color: #000000;
	justify-content:center;
}

.sign_up{	
	padding: 12px 25px 15px 25px;
	margin:0px 0px 0px 0px;
	background: #FAB804;
	border-radius: 26px;
	color: #000000;	
}
.navigation-links{
	margin:0px 15px 0px 10px;
	color: #000000;
	justify-content:center;
}
.footer_main{
	padding-left: 20px;
}
.get_in_touch{
	position: relative;
	width: 390px;
	height: 270px;
	font-family: Rubik;
	font-style: normal;
	background:transparent;
	border-radius: 20px;
	margin-left: 5px;
	padding-left: 5px;
}
.get_in_touch_content{
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
	color: #000000;
}
.get_in_touch_text{
	font-size: 30px;
}
.get_in_touch_email{
	font-size: 20px;
}
.get_in_touch_address{
	font-size: 20px;
}
.get_in_touch_mobile{
	font-size: 20px;
}
.policy{
	font-size: 12px;
}
.vector{
	left: 8.33%;
	right: 8.33%;
	top: 8.33%;
	bottom: 8.33%;
	height: 22px;
	width: 30px;
	border-radius: 0px;	
}
.get_in_touch_content{
	font-size: 20px;
}

.policy_procedure_text{
	font-size: 15px;
	color: aliceblue;
}
.nav-link {
	text-decoration: none;
	color: white;
	transition: 0.3s all;
}

.nav-link:hover {
	color: red;
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media only screen and (max-width: 500px) {
	.navbar {
		position: relative;
	}

	.nav-links {
		display: flex;
		flex-direction: column;
		position: absolute;
		text-align: center;
		width: 100%;
		top: 80px;
		left: -100%;
		transition: 0.5s all;
	}

	.nav-links.active {
		background: blue;
		left: 0;
	}

	.nav-item {
		padding: 0px 0;
		border-top: 1px solid red;
		border-bottom: 1px solid red;
	}

	.nav-icon {
		display: flex;
	}
}
.right{

	right: 10%;
	
}
.right a {
	color: #d9ff05;;
	text-decoration: none;
}
@media only screen and (max-width: 500px) {

	.footbar {
	
	position: relative;
	width: 183%;
   
	}
	.right{
		margin-top: 2%;
		padding-right: 1%;
	}
	
}