.navbar {
	margin:0 0 0 0px ;
	height: 12vh;
	width: 100%;
	/*background: #001D47;*/
	background: #06669e;
	display: flex;
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	border-radius: 0px;	
	flex-wrap: unset;
	z-index:2;
	

	/*justify-content:center;
	align-items: center;*/
}
.max-height-25vh {
	max-height: 25vh;
	
 }
.nav-logo {
	margin:0 0 0 40px;
	color: white;
	padding-top: 0px;
	justify-content:center;
}

.nav-links {
	display: grid;
	grid-template-columns: repeat(5, auto);
	grid-gap: 5px;
	padding-top: 5px;
	list-style: none;
	color: beige;
}

.sign_up_container{	
	padding: 0px 0px 0px 0px;
	margin:0px 0px 0px 0px;
	color: #000000;
	justify-content:center;
}

.sign_up{	
	padding: 12px 25px 15px 25px;
	margin:0px 0px 0px 0px;
	background: #FAB804;
	border-radius: 26px;
	color: #000000;	
}
.navigation-links{
	margin:0px 15px 0px 10px;
	color: #000000;
	justify-content:center;
}

.nav-link {
	text-decoration: none;
	color: #FAB804;
	transition: 0.3s all;
}

.nav-link:hover {
	color: red;
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media only screen and (max-width: 500px) {
	

	.nav-links {
		display: flex;
		flex-direction: column;
		position: absolute;
		text-align: center;
		width: 100%;
		top: 80px;
		left: -100%;
		transition: 0.5s all;
	}

	.nav-links.active {
		background: blue;
		left: 0;
	}

	.nav-item {
		padding: 0px 0;
		border-top: 1px solid red;
		border-bottom: 1px solid red;
	}

	.nav-icon {
		display: flex;
	}
}
.dropdown{
	display: none;
}
.link{
	height: 50px;
	  width: 60%;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  
	  padding:0 5%;
	}
.link ul{
    list-style: none;
    display: flex;
  }
  .link ul li{
    padding: 10px 10px;
    position: relative;
	right:0%;
	line-height: 0px;
	
  }
  .link ul li a{
	font-family: "Source Sans Pro",sans-serif;
    color:var(--color-white);
    text-decoration: none;
    font-size: 15px;
    transition: all 0.3s;
	
  }
  .link ul li a:hover{
    color: #a8ff05;
  }
  
.link ul li:hover .dropdown{
    display: block;
    position: absolute;
    left:0;
    top:100%;
	z-index:2;
    background-color:var(--color-black);
  }
  .dropdown ul{
    display: block;
	width:fit-content;
	height: fit-content;
    margin: 15px;
	font-family: "Source Sans Pro",sans-serif;
	text-size-adjust: 15%;

    background:#ffffff;
  }
  .dropdown ul li{
    width:170px;
    
	line-height: 2px;
	left:-5%;
	line-height: 1.55;
  }
  .dropdown ul li a{
    font-size:11px;
	color: black;
	
  }
  /******/
  #brand {
	font-weight: bold;
	font-size: 18px;
	display: flex;
	align-items: center;
  }
  
  #brand a {
	color: #09c372;
  }
  
  ul {
	list-style: none;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-around;
  }
  
  ul a {
	color: white;
  }
  
  ul li {
	padding: 5px;
	margin-left: 10px;
  }
  
  ul li:hover {
	transform: scale(1.1);
	transition: 0.3s;
  }
  
  #login,
  #signup {
	border-radius: 5px;
	padding: 5px 8px;
  }
  
  #login {
	border: 1px solid #498afb;
  }
  
  #signup {
	border: 1px solid #ff3860;
  }
  
  #signup a {
	color: #ff3860;
  }
  
  #login a {
	color: #498afb;
  }
  
  #hamburger-icon {
	margin: auto 0;
	display: none;
	cursor: pointer;
	margin-left: 5%;
  }
  
  #hamburger-icon div {

	width: 35px;
	height: 3px;
	background-color: white;
	margin: 6px 0;
	transition: 0.4s;
  }
  
  .open .bar1 {
	-webkit-transform: rotate(-45deg) translate(-6px, 6px);
	transform: rotate(-45deg) translate(-6px, 6px);
  }
  
  .open .bar2 {
	opacity: 0;
  }
  
  .open .bar3 {
	-webkit-transform: rotate(45deg) translate(-6px, -8px);
	transform: rotate(45deg) translate(-6px, -8px);
  }
  
  .open .mobile-menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
  }
  
  .mobile-menu {
	display: none;
	position: absolute;
	top: 50px;
	left: 0;
	height: calc(100vh - 50px);
	width: 100%;
  }
  
  .mobile-menu li {
	margin-bottom: 10px;
  }
  .buttonable{
	display: none;
  }
  @media only screen and (max-width: 500px) {
	.navbar {
		
	margin-top:-21%;
	width: 183%;
	
	}
	header nav {
	  display: none;
	}
  
	#hamburger-icon {
		
	  display: block;
	}

	.mobile-menu:hover {
		display: block;
		
	  }

	  .buttonable{
		margin-top: 42%;
		display: flex;
	  }
	

	  .sink li:hover .dropmenu{
		display: block;
		position: absolute;
		left:0;
		top:80%;
		z-index:2;
		background-color:var(--color-black);
	  }
	  .dropmenu ul{
		display: block;
		width:90%;
		
		height: fit-content;
		margin: 15px;
		font-family: "Source Sans Pro",sans-serif;
		text-size-adjust: 15%;
	
		background:#06669ed2;
	  }
	  .dropmenu ul li{
		width:160px;
		padding-top: 10%;
		line-height: 2px;
		left:-5%;
		line-height: 1.55;
	  }
	  .dropmenu ul li a{
		font-size:16px;
		width: auto;
		color: rgb(252, 248, 248);
		text-decoration: none;
	  }
	  .dropmenu ul li a:hover{
		color: #a8ff05;
	  }
	
	  .dropmenu ul li:hover .menulist{
		display: block;
		position: absolute;
		
		left:90%;
		top:-20%;
		z-index:2;
		background-color:var(--color-black);
	  }

.menulist{
	display: none;
}

	  .dropmenu{
		display: none;
	}


	.menulist ul{
		display: block;
		width:90%;
		
		height: fit-content;
		margin: 15px;
		font-family: "Source Sans Pro",sans-serif;
		text-size-adjust: 15%;
	
		background:#06669ed2;
	  }
	  .menulist ul li{
		width:160px;
		padding-top: 10%;
		line-height: 2px;
		left:-5%;
		line-height: 1.55;
	  }
	  .menulist ul li a{
		font-size:12px;
		color: rgb(252, 248, 248);
		text-decoration: none;
	  }
	  .menulist ul li a:hover{
		color: #a8ff05;
	  }
  }
  

  
 